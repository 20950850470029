<template>
  <div class="storeRoomForm-container">
    <form-panel ref="formPanel" :form="form" :hasHeader="false" v-bind="submitConfig" :submitUrl="submitUrl" submitText="保存" :submitSuccess="submitSuccess" :submitBefore="submitBefore" :backAfterSave="subPageType == 'inner'">
        <div class="cell-title"><span class="pre-point"></span>车辆基本信息</div>
        <el-form-item label="用户姓名" :rules="[{ required: true, message: '请选择用户', trigger: 'change' }]" prop="houseId">
          <v-select2 v-model="form.houseId" :width="500" :disabled="edit" v-bind="houseParams" @onChange="houseIdChange" :echoRequestData="echohouseId"></v-select2>
          <div>注:如查找不到姓名，请到 <span class="blueColor" @click="goPageUser">用户资料管理</span> 模块去新增用户数据</div>
        </el-form-item>
        <el-form-item label="车牌号" :rules="[{ required: true, message: '请填写车牌号', trigger: 'blur' }]" prop="carNum">
          <v-input v-model="form.carNum" :disabled="edit"></v-input>
          <span>  格式: 浙A123ab， 请使用半角数字和字母</span>
        </el-form-item>
        <el-form-item label="状态" :rules="[{ required: true, message: '请选择状态', trigger: 'change' }]" prop="carStatus">
          <v-select v-model="form.carStatus" :options="statusOps"></v-select>
        </el-form-item>
        <el-form-item label="车辆品牌">
          <div class="multi-form">
            <v-select2 v-model="form.carBrand" v-bind="carBrandParams" @onChange="carBrandChange" :echoRequestData="echoCarBrand"></v-select2>
            <v-select class="mar-left" v-model="form.carModel" :options="carModelOps"></v-select>
          </div>
        </el-form-item>
        <el-form-item label="颜色">
          <v-select v-model="form.carColor" :options="carColorlOps"></v-select>
        </el-form-item>
        <el-form-item label="行驶证">
          <v-uploader :action="uploadURL" :limit="2" :imgUrls.sync="drivingLicensePicList">
            <template #tip>
              <!-- <span>注：建议宽640px高640px，图片小于512KB</span> -->
            </template>
          </v-uploader>
        </el-form-item>
    </form-panel>
  </div>
</template>
<script>
import {
  getOwnerList,
  getCarBrands,
  getCarBrandsBySuperId,
  saveOwnerCar,
  updateOwnerCar,
  queryOwnerCarBasic,
  licensePicUploadUrl
} from './api'
import { carPlateRegular } from 'common/regular'
import { vUploader } from 'components/control'
import _ from 'lodash'
export default {
  components: {
    vUploader
  },
  props:{editQuery:Object,},
  data () {
    return {
      uploadURL: licensePicUploadUrl,
      edit: false,
      form: {
        userId: '',
        houseId: '',
        communityId: '',
        carNum: '',
        carStatus: 0,
        carBrand: '',
        carModel: undefined,
        carColor: undefined,
        drivingLicensePic: ''
      },
      drivingLicensePicList: [],
      submitConfig: {
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      submitUrl:"",
      houseParams: {
        searchUrl: getOwnerList,
        request: {
          text: 'ownerName',
          value: 'houseId'
        },
        response: {
          text: 'name',
          value: 'houseId'
        },
        method: 'get'
      },
      carBrandParams: {
        searchUrl: getCarBrands,
        request: {
          text: 'codeword'
        },
        response: {
          text: 'name',
          value: 'name'
        },
        method: 'get'
      },
      statusOps: [{
        text: '正常',
        value: 0
      }, {
        text: '关闭',
        value: 1
      }],
      carModelOps: [],
      carColorlOps: [
        {
          text: '请选择',
          value: undefined
        },
        {
          text: '黑',
          value: '黑'
        },
        {
          text: '银',
          value: '银'
        },
        {
          text: '白',
          value: '白'
        },
        {
          text: '灰',
          value: '灰'
        },
        {
          text: '红',
          value: '红'
        },
        {
          text: '黄',
          value: '黄'
        },
        {
          text: '蓝',
          value: '蓝'
        },
        {
          text: '绿',
          value: '绿'
        },
        {
          text: '橙',
          value: '橙'
        },
        {
          text: '紫',
          value: '紫'
        },
        {
          text: '粉',
          value: '粉'
        },
        {
          text: '棕',
          value: '棕'
        },
        {
          text: '其他',
          value: '其他'
        }
      ],
      carBrandId: '',
      query:{}
    }
  },
  computed: {
    subPageType() {
      return this.$store.state.theme['sub-page-type'] || 'inner'
    },
  },
  created () {
    this.query = this.subPageType == 'drawer' ? this.editQuery : this.$route.query
    let formId = this.query.id
    let userId = this.query.userId
    let houseId = this.query.houseId
    this.submitUrl = this.query.id ? updateOwnerCar : saveOwnerCar
    if(!this.subPageType == 'drawer'){
      if (formId) {
        this.$setBreadcrumb('编辑')
        this.getFormDetail(formId)
      } else if (userId && houseId) {
        this.$setBreadcrumb('新增')
        this.setUser(userId, houseId)
      } else {
        this.$setBreadcrumb('新增')
      }
    }else{
       if (formId) {
        this.getFormDetail(formId)
       }else if (userId && houseId) {
        this.setUser(userId, houseId)
       }
    }
    
  },
  methods: {
    submitSuccess(){
      this.subPageType == 'drawer' && this.$emit('closeDrawer','车辆信息');
      return true
    },
    submitBefore (data) {
      data.drivingLicensePic = this.drivingLicensePicList.join(',')
      return data
    },
    houseIdChange (val) {
      val && (this.form.userId = val.id)
      val && (this.form.communityId = val.communityId)
    },
    carBrandChange (val) {
      if (val) {
        this.$axios.post(getCarBrandsBySuperId, { superid: val.id }).then((res) => {
          if (res.status == 100) { // eslint-disable-line
            let data = res.data
            this.carModelOps = data.map((item) => {
              return {
                text: item.text,
                value: item.text
              }
            })
            let index = _.findIndex(this.carModelOps, value => {
              return value.text === this.form.carModel
            })
            if (index < 0) {
              this.form.carModel = undefined
            }
          }
        })
      } else {
        this.form.carModel = undefined
        this.carModelOps = []
      }
    },
    getFormDetail (id) {
      this.$axios.post(queryOwnerCarBasic, { id }).then((res) => {
        if (res.status == 100) { // eslint-disable-line
          let data = res.data
          this.carBrandId = data.carBrandId
          this.form = {
            userId: data.userId,
            houseId: data.houseId,
            communityId: data.communityId,
            carNum: data.carNum,
            carStatus: data.carStatus,
            carBrand: data.carBrand,
            carModel: data.carModel || undefined,
            carColor: data.carColor || undefined,
            id
          }
          this.edit = true
          const list = data.drivingLicensePic.split(',').map(item => {
            if (item.indexOf('http') == -1) {
              return window.location.origin + item
            } else {
              return item
            }
          })
          this.drivingLicensePicList = list
          
          if (data.carBrandId) {
            this.$nextTick(() => {
              this.carBrandChange({ id: data.carBrandId }, data.carModel)
            }, 100)
          }
        }
      })
    },
    setUser (userId, houseId) {
      this.$set(this.form, 'userId', userId)
      this.$set(this.form, 'houseId', houseId)
    },
    echohouseId (data) {
      let newData = {
        userId: `{"userId":"${this.form.userId}","houseId":"${data.houseId}"}`
      }
      return newData
    },
    echoCarBrand () {
      let newData = {
        carBrandId: this.carBrandId
      }
      return newData
    },
    goPageUser () {
      this.$router.push({
        name: 'serverOwnerAction!getAllOwnerList.action2'
      })
    },
    validateCarNumber (rule, value, callback) {
      if (!value) {
        callback(new Error('请填写车牌号'))
      } else if (!carPlateRegular.test(value)) {
        callback(new Error('请填写正确车牌号'))
      } else {
        callback()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.storeRoomForm-container {
  background: #fff;
  text-align: left;
  padding-left: 20px;
}
.cell-title{
  text-align: left;
  padding-left: 10px;
  margin: 15px 0;
  display: flex;
  align-items: center;
  .pre-point {
    // display: inline-block;
    border-radius: 50%;
    background-color: #dce2e7;
    height: 5px;
    width: 5px;
    margin-right: 5px;
  }
}
.multi-form {
  display: flex;
  align-items: center;
  .mar-left {
    margin-left: 20px;
  }
}
.blueColor{
  color: #337ab7;
  cursor: pointer;
}
</style>
