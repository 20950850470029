/*
 * @Description: 主要功能
 * @Author: FCC
 * @Date: 2019-08-13 13:32:57
 * @LastEditors: 小广
 * @LastEditTime: 2019-08-20 15:37:01
 */
// 获取列表
const getListURL = `${API_CONFIG.baseURL}serverOwnerAction!queryOwnerCarList.action`
// 导出列表
const exportListURL = `${API_CONFIG.baseURL}serverOwnerAction!exportOwnerCarToExcel.action`
// 获取组织列表
const getOrgListURL = `${API_CONFIG.butlerBaseURL}orgInfo/searchOrgByKeyword`
// 批量操作
const batchURL = `${API_CONFIG.baseURL}smUserAccountAction!batchUpdateRechargeCardStatus.action`
// 获取用户列表
const getOwnerList = `${API_CONFIG.baseURL}serverCodewordAction!getOwners.action`
// 获取车辆品牌列表
const getCarBrands = `${API_CONFIG.baseURL}serverCodewordAction!getCarBrands.action`
// const getCarBrands = `${API_CONFIG.butlerBaseURL}sysCodeword/getSysCodeWordByCodeLevel?typeValue=CAR_BRAND_MODEL`
// 获取车辆品牌2级列表
const getCarBrandsBySuperId = `${API_CONFIG.baseURL}serverOwnerAction!getCarBrandsBySuperId.action`
// const getCarBrandsBySuperId = `${API_CONFIG.butlerBaseURL}sysCodeword/getSysCodeWordByCodeLevel`
// 保存车辆
const saveOwnerCar = `${API_CONFIG.baseURL}serverOwnerAction!saveOwnerCar.action`
// const saveOwnerCar = `${API_CONFIG.butlerBaseURL}user/register/addOwnerCarNew`
// 更新车辆
const updateOwnerCar = `${API_CONFIG.butlerBaseURL}ownerInfo/updateOwnerCarNew`
// const updateOwnerCar = `${API_CONFIG.baseURL}serverOwnerAction!updateOwnerCar.action`
// 获取车辆详情
const queryOwnerCarBasic = `${API_CONFIG.baseURL}serverOwnerAction!queryOwnerCarBasic.action`
// 库房导入接口
const uploadUrl = `${API_CONFIG.butlerBaseURL}ownerInfo/importVehiclePosition`
// 车辆行驶证上传接口
// const licensePicUploadUrl = `https://yshyanfa.lcfw.co/upload?module=shopgoods`
const licensePicUploadUrl = `${API_CONFIG.uploadURL}?module=shopgoods`

export {
  getListURL,
  exportListURL,
  getOrgListURL,
  batchURL,
  getOwnerList,
  getCarBrands,
  getCarBrandsBySuperId,
  saveOwnerCar,
  updateOwnerCar,
  queryOwnerCarBasic,
  uploadUrl,
  licensePicUploadUrl
}
